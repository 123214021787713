import { lazy } from 'react';

const HelpCenter = lazy(() => import('../../views/HelpCenter/HelpCenter'));

const routes = [
  {
    path: '/:root(getting-started)',
    component: HelpCenter,
    sl: true,
    so: true,
  },
];

export default routes;
