import { lazy } from 'react';

const LocationsListNavMenu = lazy(
  () => import('../../shared/NavMenus/Locations/LocationsListNavMenu'),
);
const LocationShowNavMenu = lazy(
  () => import('../../shared/NavMenus/Locations/LocationShowNavMenu'),
);

const routes = [
  {
    path: '/:root(locations)',
    exact: true,
    component: LocationsListNavMenu,
    so: true,
    sl: false,
  },
  {
    path: [
      '/:root(locations)/:slug',
      '/:root(locations)/:slug/price-list',
      '/:root(locations)/:slug/api-settings',
      '/:root(locations)/:slug/dme-files/:action(new)',
      '/:root(locations)/:slug/design-preferences',
      '/:root(locations)/:slug/billing',
      '/:root(locations)/:slug/design-profile',
      '/:root(locations)/:slug/billing/invoices/:hexInvoiceNumber',
      '/:root(locations)/:slug/integrations',
      '/:root(locations)/:slug/integrations/:action(new)',
      '/:root(locations)/:slug/integrations/:id/:action(edit)',
    ],
    exact: true,
    component: LocationShowNavMenu,
    so: true,
    sl: false,
  },
  {
    path: [
      '/:root(locations)',
      '/:root(locations)/price-list',
      '/:root(locations)/api-settings',
      '/:root(locations)/dme-files/:action(new)',
      '/:root(locations)/design-preferences',
      '/:root(locations)/design-profile',
      '/:root(locations)/billing',
      '/:root(locations)/billing/invoices/:hexInvoiceNumber',
      '/:root(locations)/integrations',
      '/:root(locations)/integrations/:action(new)',
      '/:root(locations)/integrations/:id/:action(edit)',
    ],
    exact: true,
    component: LocationShowNavMenu,
    so: false,
    sl: true,
  },
];

export default routes;
