import { lazy } from 'react';

const CadFileForm = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/CadFiles/CadFileForm/CadFileForm'
    ),
);

const routes = [
  {
    path: '/:root(locations)/:slug/dme-files/:action(new)',
    component: CadFileForm,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/dme-files/:action(new)',
    component: CadFileForm,
    sl: true,
    so: false,
  },
];

export default routes;
