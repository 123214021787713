import { lazy } from 'react';

const ProfileListNavMenu = lazy(() =>
  import('@fullcontour/common').then((module) => ({
    default: module.ProfileListNavMenu,
  })),
);

const routes = [
  {
    path: '/:root(profile)',
    exact: false,
    component: ProfileListNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
