import { lazy } from 'react';

const UsersListNavMenu = lazy(() =>
  import('@fullcontour/common').then((module) => ({
    default: module.UsersListNavMenu,
  })),
);
const UserShowNavMenu = lazy(() =>
  import('@fullcontour/common').then((module) => ({
    default: module.UserShowNavMenu,
  })),
);

const routes = [
  {
    path: '/:root(users)',
    exact: true,
    component: UsersListNavMenu,
    so: true,
    sl: true,
  },
  {
    path: '/:root(users)/:id',
    exact: true,
    component: UserShowNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
