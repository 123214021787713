import { lazy } from 'react';

const LocationFormCustomer = lazy(
  () => import('../../views/Locations/LocationForms/LocationFormCustomer'),
);

const LocationShowInner = lazy(
  () => import('../../views/Locations/LocationShow/LocationShowInner'),
);

const LocationsListDataTable = lazy(
  () => import('../../views/Locations/LocationsList/LocationsListDataTable'),
);

const LocationPriceList = lazy(
  () => import('../../views/Locations/LocationPriceList/LocationPriceList'),
);

const LocationCustomerDesignPreferences = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/Locations/LocationCustomerDesignPreferences/LocationCustomerDesignPreferencesForm'
    ),
);

const ApiSettings = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/ApiSettings/ApiSettingsForm/ApiSettingsForm'
    ),
);

const LocationCustomerBilling = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/Locations/LocationCustomerBilling/LocationCustomerBilling'
    ),
);
const LocationDesignProfile = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/Locations/LocationDesignProfile/LocationDesignProfile'
    ),
);

const routes = [
  {
    path: '/:root(locations)',
    component: LocationsListDataTable,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)',
    component: LocationShowInner,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/:slug/:action(edit)',
    component: LocationFormCustomer,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/price-list',
    component: LocationPriceList,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/api-settings',
    component: ApiSettings,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/design-preferences',
    component: LocationCustomerDesignPreferences,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug/billing',
    component: LocationCustomerBilling,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:action(edit)',
    component: LocationFormCustomer,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/price-list',
    component: LocationPriceList,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/api-settings',
    component: ApiSettings,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/design-preferences',
    component: LocationCustomerDesignPreferences,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/billing',
    component: LocationCustomerBilling,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/design-profile',
    component: LocationDesignProfile,
    sl: true,
    so: false,
  },
  {
    path: '/:root(locations)/:slug/design-profile',
    component: LocationDesignProfile,
    sl: false,
    so: true,
  },
  {
    path: '/:root(locations)/:slug',
    component: LocationShowInner,
    sl: false,
    so: true,
  },
];

export default routes;
