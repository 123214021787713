import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import {
  BaseLoader,
  guidedFlow,
  OverpanelDrawerProvider,
  OverpanelFilesContext,
  pendingCasesAmount,
  sendingOrders,
  sendingTempOrders,
} from '@fullcontour/common';
import {
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
  GET_TEMP_ORDERS_CUSTOMER,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import { TempOrdersList } from '../../TempOrders';
import OverpanelDrawer from '../OverpanelDrawer/OverpanelDrawer';
import OverpanelInformation from '../OverpanelInformation/OverpanelInformation';
import { BrowseFiles, ProgressInfo } from './components';
import OverpanelCaseItem from './OverpanelCaseItem';
import OverpanelCasesHeader from './OverpanelCasesHeader';

const progressClosePanelStylesBox = {
  position: 'absolute',
  width: '50%',
  left: '50%',
  transform: 'translateX(-66%)',
  display: 'flex',
  flexDirection: 'column',
};

function OverpanelCases(props) {
  const {
    data: { customerLocation, softwareNodes },
  } = props;

  const isGuidedFlow = useReactiveVar(guidedFlow);
  const submittingOrders = useReactiveVar(sendingOrders);
  const submittingTempOrders = useReactiveVar(sendingTempOrders);

  const [open, setOpen] = useState(false);
  const [drawerBody, setDrawerBody] = useState(null);

  const inputRef = useRef(null);
  const { pushFiles, overpanelOpen, files } = useContext(OverpanelFilesContext);
  const [fetchTempOrders, { data, loading }] = useLazyQuery(
    GET_TEMP_ORDERS_CUSTOMER,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: dataL, refetch } = useQuery(
    GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        fetchTempOrders();
      },
    },
  );

  useEffect(() => {
    if (data && data.tempOrders) pendingCasesAmount(data.tempOrders.length);
  }, [data]);

  function onChangeHandler({ target: { files } }) {
    const acceptedFiles = [...files].map((file) => ({
      file,
      isChecked: false,
      id: uuidv4(),
    }));
    pushFiles(acceptedFiles);
  }

  const overpanelBody = () => {
    if (overpanelOpen === 'pending') {
      if (loading && !data)
        return <BaseLoader loading title="Pending cases " />;
      return data ? (
        <TempOrdersList
          queriedTempOrders={data.tempOrders}
          customerLocation={data.customerLocation}
          softwareNodes={data.softwareNodes}
          labLocationGuideCategoryGroups={
            dataL?.labLocationGuideCategoryGroups || []
          }
        />
      ) : (
        <BaseLoader loading title="Pending cases " />
      );
    }

    const infoPanelVisible = !files.length;
    return (
      <div
        className="overpanelCasesContainer"
        style={{ display: files.length ? 'block' : 'flex' }}
      >
        <div className="overpanelCasesGrid">
          {submittingOrders.totalCases && overpanelOpen ? (
            <progress
              className="progress is-primary is-small"
              value={submittingOrders.casesSent}
              max={submittingOrders.totalCases}
              style={{
                width: '100%',
              }}
            >
              {overpanelOpen && (
                <>
                  Sending cases
                  <BaseLoader loading style={{ marginLeft: '5px' }} />
                </>
              )}
            </progress>
          ) : null}
          {!overpanelOpen && (
            <ProgressInfo
              submittingOrders={submittingOrders}
              submittingTempOrders={submittingTempOrders}
              progressClosePanelStylesBox={progressClosePanelStylesBox}
              overpanelOpen={overpanelOpen}
            />
          )}
          {files.length ? <OverpanelCasesHeader /> : null}
          <div className={infoPanelVisible ? 'is-flex' : ''}>
            <OverpanelInformation isVisible={infoPanelVisible} />
            <div style={{ width: '100%' }}>
              <div
                className="overpanelCasesInnerGrid"
                style={{
                  overflowY: files.length > 0 ? 'scroll' : 'auto',
                }}
              >
                <TransitionGroup className="caseTranistion" component={null}>
                  {customerLocation &&
                    softwareNodes &&
                    files.map((fileContainer, index) => (
                      <CSSTransition
                        key={fileContainer.id}
                        timeout={500}
                        classNames="fade"
                        component={null}
                      >
                        <OverpanelCaseItem
                          file={fileContainer.file}
                          fileIndex={index}
                          customerLocation={customerLocation}
                          softwareNodes={softwareNodes}
                          labLocationGuideCategoryGroups={
                            dataL?.labLocationGuideCategoryGroups || []
                          }
                        />
                      </CSSTransition>
                    ))}
                </TransitionGroup>
                <BrowseFiles
                  inputRef={inputRef}
                  onChangeHandler={onChangeHandler}
                  files={files}
                  isGuidedFlow={isGuidedFlow}
                />
              </div>
            </div>
          </div>
        </div>
        <OverpanelDrawer />
      </div>
    );
  };

  return (
    <OverpanelDrawerProvider
      value={{
        open,
        toggle: () => setOpen((prev) => !prev),
        body: drawerBody,
        setDrawerBody,
        refetch,
      }}
    >
      {overpanelBody()}
    </OverpanelDrawerProvider>
  );
}

OverpanelCases.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OverpanelCases;
