import { lazy } from 'react';

const IntegrationsListQueryWrapper = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/Integrations/IntegrationsList/IntegrationsListQueryWrapper'
    ),
);

const IntegrationForm = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/Integrations/IntegrationForm/IntegrationForm'
    ),
);

const routes = [
  {
    path: ['/:root(locations)/:slug/integrations'],
    component: IntegrationsListQueryWrapper,
    sl: false,
    so: true,
  },
  {
    path: ['/:root(locations)/integrations'],
    component: IntegrationsListQueryWrapper,
    sl: true,
    so: false,
  },
  {
    path: [
      '/:root(locations)/:slug/integrations/:action(new)',
      '/:root(locations)/:slug/integrations/:id/:action(edit)',
    ],
    component: IntegrationForm,
    sl: false,
    so: true,
  },
  {
    path: [
      '/:root(locations)/integrations/:action(new)',
      '/:root(locations)/integrations/:id/:action(edit)',
    ],
    component: IntegrationForm,
    sl: true,
    so: false,
  },
];

export default routes;
