import { lazy } from 'react';

const InvoiceShow = lazy(
  () => import('@fullcontour/common/src/components/views/Invoices/InvoiceShow'),
);

const routes = [
  {
    path: [
      '/:root(locations)/:slug/billing/invoices/:hexInvoiceNumber',
      '/:root(organizations)/billing/invoices/:hexInvoiceNumber',
    ],
    component: InvoiceShow,
    sl: false,
    so: true,
  },
  {
    path: ['/:root(locations)/billing/invoices/:hexInvoiceNumber'],
    component: InvoiceShow,
    sl: true,
    so: false,
  },
];

export default routes;
