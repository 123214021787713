import { lazy } from 'react';

const AccountMenuNew = lazy(() =>
  import('@fullcontour/common').then((module) => ({
    default: module.AccountMenuNew,
  })),
);
const routes = [
  {
    path: [
      '/:root(users)/:action(new)',
      '/:root(locations)/:slug/:action(edit)',
      '/:root(organizations)/:action(edit)',
      '/:root(users)/:id/:action(edit)',
    ],
    exact: true,
    component: AccountMenuNew,
    so: true,
    sl: false,
  },
  {
    path: [
      '/:root(users)/:action(new)',
      '/:root(locations)/:action(edit)',
      '/:root(users)/:id/:action(edit)',
    ],
    exact: true,
    component: AccountMenuNew,
    so: false,
    sl: true,
  },
];

export default routes;
