import { lazy } from 'react';

const UserFormCustomer = lazy(
  () => import('../../views/Users/UserForms/UserFormCustomer'),
);
const UsersListDataTable = lazy(
  () => import('../../views/Users/UsersList/UsersListDataTable'),
);
const UserShowInner = lazy(
  () => import('../../views/Users/UserShow/UserShowInner'),
);

const routes = [
  {
    path: '/:root(users)',
    component: UsersListDataTable,
    sl: true,
    so: true,
  },
  {
    path: '/:root(users)/:id?/:action(edit|new)',
    component: UserFormCustomer,
    sl: true,
    so: true,
  },
  {
    path: '/:root(users)/:id',
    component: UserShowInner,
    sl: true,
    so: true,
  },
];

export default routes;
