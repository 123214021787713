import { useQuery } from '@apollo/client';
import { GET_BILLING_STATUS } from '@fullcontour/shared-api';
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CurrentUserContext, Message } from '../../shared';

function PriorityMessage() {
  const { currentUser } = useContext(CurrentUserContext);
  const [visible, setVisible] = useState(true);

  const { error, loading } = useQuery(GET_BILLING_STATUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: currentUser?.scopeToLocation === false,
    onCompleted: (data) => {
      setVisible(data.billingStatus);
    },
  });

  function dismiss() {
    setVisible(false);
  }

  if (loading || error) return null;

  return visible && currentUser?.scopeToLocation ? (
    <Message
      type="warning"
      title="There is a problem with your billing information"
      onDismiss={dismiss}
      dismissable
      style={{
        position: 'fixed',
        marginLeft: '10%',
        marginRight: '10%',
        width: '80%',
        zIndex: '9999',
        top: '45px',
      }}
    >
      <p>
        Please update your credit card information{' '}
        <NavLink to="/locations/billing">here</NavLink> to continue submitting
        orders.
      </p>
    </Message>
  ) : null;
}

PriorityMessage.propTypes = {};

export default PriorityMessage;
