import { useReactiveVar } from '@apollo/client';
import {
  BaseLoader,
  CurrentUserContext,
  OrderDropzone,
  OverpanelFilesContext,
  pendingCasesAmount as pendingCasesAmountVar,
  SidebarLayout,
} from '@fullcontour/common';
import { memo, Suspense, useContext } from 'react';
import Overpanel from '../views/Overpanel/Overpanel';
import { WelcomeModal } from '../views/WelcomeModal';

function BodyElement() {
  const { currentUser, enableSimpleUploader } = useContext(CurrentUserContext);
  const { overpanelOpen, toggleOverpanel } = useContext(OverpanelFilesContext);
  const pendingCasesAmount = useReactiveVar(pendingCasesAmountVar);
  return (
    <>
      {currentUser?.scopeToLocation && !enableSimpleUploader && (
        <Suspense fallback={<BaseLoader loading />}>
          <Overpanel
            overpanelOpen={!!overpanelOpen}
            currentUser={currentUser}
            pendingCasesAmount={pendingCasesAmount}
          />
        </Suspense>
      )}
      <OrderDropzone overpanelOpen={!!overpanelOpen}>
        <SidebarLayout />
      </OrderDropzone>
      <WelcomeModal toggleOverpanel={(status) => toggleOverpanel(status)} />
    </>
  );
}

export default memo(BodyElement);
