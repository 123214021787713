import { lazy } from 'react';

const HelpCenterNavMenu = lazy(
  () => import('../../shared/NavMenus/HelpCenter/HelpCenterNavMenu'),
);

const routes = [
  {
    path: '/:root(getting-started)',
    exact: false,
    component: HelpCenterNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
