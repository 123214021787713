const redirects = [
  {
    from: '/orders/pending',
    to: '/orders',
    state: {
      openOverpanel: true,
    },
    exact: true,
    key: 1,
  },
];

export default redirects;
