import { lazy } from 'react';

const ReportsListQueryWrapper = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/views/Reports/ReportsList/ReportsListQueryWrapper'
    ),
);

const ReportForm = lazy(
  () => import('@fullcontour/common/src/components/views/Reports/ReportForm'),
);

const ReportSaved = lazy(
  () => import('@fullcontour/common/src/components/views/Reports/ReportSaved'),
);

const routes = [
  {
    path: '/:root(reports)',
    component: ReportsListQueryWrapper,
    sl: true,
    so: true,
  },
  {
    path: '/:root(reports)/:type(new)',
    component: ReportForm,
    sl: true,
    so: true,
  },
  {
    path: '/:root(reports)/:type(saved)/:id',
    component: ReportSaved,
    sl: true,
    so: true,
  },
];

export default routes;
