import { lazy } from 'react';

const OrganizationShowInner = lazy(
  () =>
    import('../../views/Organizations/OrganizationShow/OrganizationShowInner'),
);

const OrganizationFormCustomer = lazy(
  () =>
    import(
      '../../views/Organizations/OrganizationForms/OrganizationFormCustomer'
    ),
);

const OrganizationCustomerBilling = lazy(
  () =>
    import(
      '../../views/Organizations/OrganizationCustomerBilling/OrganizationCustomerBilling'
    ),
);

const routes = [
  {
    path: '/:root(organizations)/:action(edit)',
    component: OrganizationFormCustomer,
    sl: false,
    so: true,
  },
  {
    path: '/:root(organizations)/billing',
    component: OrganizationCustomerBilling,
    sl: false,
    so: true,
  },
  {
    path: '/:root(organizations)',
    component: OrganizationShowInner,
    sl: false,
    so: true,
  },
];

export default routes;
