/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import { ApolloProvider } from '@apollo/client';
import {
  apolloClient,
  ErrorBoundry,
  history,
  MediaContextProvider,
  RootLoader,
  ScrollToTop,
} from '@fullcontour/common';
import '@fullcontour/common/src/config/i18n/i18next';
import { Amplify } from 'aws-amplify';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Authenticator } from '@fullcontour/auth';
import App from './App';
import awsAuthConfig from './config/amplify/awsAuthConfig';
import './index.scss';
import * as serviceWorker from './serviceWorker';

LicenseManager.setLicenseKey(
  'CompanyName=Fullcontour, LLC,LicensedGroup=matador-ag-grid,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025483,ExpiryDate=6_April_2023_[v2]_MTY4MDczNTYwMDAwMA==0ee1345068bc015177cdd41d650d96fa',
);

const ampConfig = awsAuthConfig[import.meta.env.VITE_ENV];
// const analyticsConfig = awsAnalyticsConfig[import.meta.env.VITE_ENV];

Amplify.configure({ ...ampConfig });
// Analytics.configure(analyticsConfig);

if (import.meta.env.VITE_ENV !== 'production') {
  localStorage.setItem('debug', 'customer-portal:*');
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={apolloClient}>
    <Suspense fallback={<RootLoader loading />}>
      <ErrorBoundry>
        <Router history={history}>
          <ScrollToTop>
            <MediaContextProvider>
              <Authenticator>
                <App client={apolloClient} />
              </Authenticator>
            </MediaContextProvider>
          </ScrollToTop>
        </Router>
      </ErrorBoundry>
    </Suspense>
  </ApolloProvider>,
);

serviceWorker.unregister();
