import { lazy } from 'react';

const ReportsListNavMenu = lazy(
  () =>
    import(
      '@fullcontour/common/src/components/shared/NavMenus/Reports/ReportsListNavMenu'
    ),
);

const routes = [
  {
    path: '/:root(reports)',
    exact: false,
    component: ReportsListNavMenu,
    so: true,
    sl: true,
  },
];

export default routes;
