import { lazy } from 'react';

const OrdersList = lazy(
  () => import('../../views/Orders/OrdersList/OrdersList'),
);
const OrderForm = lazy(() => import('../../views/Orders/OrderForm/OrderForm'));

const routes = [
  {
    path: [
      '/:root(orders)',
      '/:root(orders)/:status(sent_for_design|design_in_progress|rush_design|hold_released|redesign|on_hold|needs_approval|sent_for_approval|ready_for_download|sent_to_manufacturer|at_manufacturer|manufacturing_hold|shipped|cancelled|complete)',
    ],
    exact: true,
    component: OrdersList,
    so: true,
    sl: true,
  },
  {
    path: '/:root(orders)/:slug?/:action(new|edit)',
    exact: true,
    component: OrderForm,
    so: true,
    sl: true,
  },
];

export default routes;
