export const baseStyle = {
  outline: 0,
};

export const activeStyle = {
  backgroundColor: 'rgba(47, 139, 220, 0.05)',
};

export const rejectedStyle = {
  backgroundColor: 'rgba(47, 139, 220, 0.05)',
};
