import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef } from 'react';
import CaseActionsButtonSubmitInner from './CaseActionsButtonSubmitInner';

function CaseActionsButtonSubmit(props) {
  const { submitForm, isSubmitting, submitCount, isValid } = useFormikContext();
  const isSubmittingRef = useRef(isSubmitting);
  const {
    uploading,
    uploadPercentage,
    submitAll,
    submit,
    triggerSubmit,
    cancelSubmit,
    fileIndex,
  } = props;
  const submitAllRef = useRef(submitAll);
  useEffect(() => {
    if (
      submit &&
      !uploading &&
      uploadPercentage >= 100 &&
      !isSubmitting &&
      submitCount === 0 &&
      isValid
    ) {
      submitForm();
    }
    if (isSubmittingRef.current && !isSubmitting) {
      cancelSubmit();
    }
    if (!submitAllRef.current && submitAll) {
      triggerSubmit();
      submitAllRef.current = submitAll;
    }
    if (submitAllRef.current && !submitAll) {
      cancelSubmit();
      submitAllRef.current = submitAll;
    }
  }, [submitAll, submit, uploading, uploadPercentage]);

  return !submit ? (
    <CaseActionsButtonSubmitInner
      triggerSubmit={triggerSubmit}
      fileIndex={fileIndex}
    />
  ) : null;
}

CaseActionsButtonSubmit.propTypes = {
  uploading: PropTypes.bool.isRequired,
  uploadPercentage: PropTypes.number.isRequired,
  submitAll: PropTypes.bool.isRequired,
  submit: PropTypes.bool.isRequired,
  triggerSubmit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  fileIndex: PropTypes.number.isRequired,
};

export default memo(CaseActionsButtonSubmit);
