import { lazy } from 'react';

const OrganizationShowNavMenu = lazy(
  () => import('../../shared/NavMenus/Organizations/OrganizationShowNavMenu'),
);

const routes = [
  {
    path: [
      '/:root(organizations)',
      '/:root(organizations)/billing',
      '/:root(organizations)/billing/invoices/:hexInvoiceNumber',
    ],
    exact: true,
    component: OrganizationShowNavMenu,
    so: true,
    sl: false,
  },
];

export default routes;
